var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.nowProductObj
    ? _c(
        "div",
        { staticClass: "unlockSingle" },
        [
          _c("div", { staticClass: "unlockSingle__bg" }),
          _c("div", { staticClass: "unlockSingle__title" }, [
            _c("div", { staticClass: "unlockSingle__title__inside" }, [
              _c("h1", [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.nowProductObj.Code == "All"
                        ? "全部購買"
                        : _vm.nowProductObj.cn + "座"
                    ) +
                    "\n      "
                )
              ]),
              _c("h2", [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.nowProductObj.Code == "All"
                        ? _vm.nowProductObj.Name.split("-")[0]
                        : _vm.nowProductObj.en
                    ) +
                    "\n      "
                )
              ])
            ])
          ]),
          _c("div", {
            staticClass: "unlockSingle__quote",
            domProps: { innerHTML: _vm._s(_vm.nowProductObj.Description) }
          }),
          _c("div", { staticClass: "unlockSingle__intro" }, [
            _vm._m(0),
            _c("div", { staticClass: "unlockSingle__intro__content" }, [
              _c("div", {
                staticClass: "unlockSingle__intro__content__word",
                domProps: { innerHTML: _vm._s(_vm.nowProductObj.Introduction) }
              })
            ])
          ]),
          _c(
            "div",
            {
              staticClass: "unlockSingle__toBuy",
              class: { isPersonal: _vm.useType == "personal" }
            },
            [
              _c("div", { staticClass: "unlockSingle__toBuy__buySetting" }, [
                _c(
                  "div",
                  { staticClass: "unlockSingle__toBuy__buySetting__useType" },
                  [
                    _c("div", { staticClass: "buy_area__useType__switch" }, [
                      _c(
                        "label",
                        {
                          class: { active: _vm.useType == "personal" },
                          attrs: { for: "personal" }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.useType,
                                expression: "useType"
                              }
                            ],
                            attrs: {
                              type: "radio",
                              name: "useType",
                              id: "personal",
                              value: "personal"
                            },
                            domProps: {
                              checked: _vm._q(_vm.useType, "personal")
                            },
                            on: {
                              change: function($event) {
                                _vm.useType = "personal"
                              }
                            }
                          }),
                          _c("span", [_vm._v("自用")])
                        ]
                      ),
                      _c(
                        "label",
                        {
                          class: { active: _vm.useType == "gift" },
                          attrs: { for: "gift" }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.useType,
                                expression: "useType"
                              }
                            ],
                            attrs: {
                              type: "radio",
                              name: "useType",
                              id: "gift",
                              value: "gift"
                            },
                            domProps: { checked: _vm._q(_vm.useType, "gift") },
                            on: {
                              change: function($event) {
                                _vm.useType = "gift"
                              }
                            }
                          }),
                          _c("span", [_vm._v("送禮")])
                        ]
                      )
                    ])
                  ]
                ),
                _vm.useType == "gift"
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "unlockSingle__toBuy__buySetting__container"
                      },
                      [
                        _c("div", { staticClass: "giftArea" }, [
                          _vm._m(1),
                          _c("div", { staticClass: "buy_area__giftMode" }, [
                            _c(
                              "div",
                              { staticClass: "buy_area__giftMode__radioGruop" },
                              [
                                _c("label", { attrs: { for: "memberId" } }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.giftMode,
                                        expression: "giftMode"
                                      }
                                    ],
                                    attrs: {
                                      type: "radio",
                                      name: "giftMode",
                                      id: "memberId",
                                      value: "memberId"
                                    },
                                    domProps: {
                                      checked: _vm._q(_vm.giftMode, "memberId")
                                    },
                                    on: {
                                      change: function($event) {
                                        _vm.giftMode = "memberId"
                                      }
                                    }
                                  }),
                                  _c("span", [_vm._v("會員 ID")])
                                ]),
                                _c("label", { attrs: { for: "code" } }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.giftMode,
                                        expression: "giftMode"
                                      }
                                    ],
                                    attrs: {
                                      type: "radio",
                                      name: "giftMode",
                                      id: "code",
                                      value: "code"
                                    },
                                    domProps: {
                                      checked: _vm._q(_vm.giftMode, "code")
                                    },
                                    on: {
                                      change: function($event) {
                                        _vm.giftMode = "code"
                                      }
                                    }
                                  }),
                                  _c("span", [_vm._v("兌換碼")])
                                ])
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "buy_area__giftMode__notice" },
                              [
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.giftMode == "memberId",
                                        expression: "giftMode == 'memberId'"
                                      }
                                    ]
                                  },
                                  [_vm._v("贈送禮物到對方的禮物盒。")]
                                ),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.giftMode == "code",
                                        expression: "giftMode == 'code'"
                                      }
                                    ]
                                  },
                                  [_vm._v("以兌換碼方式贈送禮物給收禮者。")]
                                )
                              ]
                            )
                          ]),
                          _vm.useType == "gift" && _vm.giftMode == "memberId"
                            ? _c(
                                "div",
                                {
                                  staticClass: "buy_area__receiverMemberId",
                                  class: { onError: _vm.memberIdErrorMsg }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "buy_area__receiverMemberId__form"
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.trim",
                                            value: _vm.receiverMemberId,
                                            expression: "receiverMemberId",
                                            modifiers: { trim: true }
                                          }
                                        ],
                                        attrs: {
                                          type: "text",
                                          id: "receiverMemberId",
                                          disabled: _vm.isReceiverMemberIdCheak,
                                          placeholder: "請輸入對方會員 ID"
                                        },
                                        domProps: {
                                          value: _vm.receiverMemberId
                                        },
                                        on: {
                                          input: [
                                            function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.receiverMemberId = $event.target.value.trim()
                                            },
                                            function($event) {
                                              _vm.memberIdErrorMsg = ""
                                            }
                                          ],
                                          blur: function($event) {
                                            return _vm.$forceUpdate()
                                          }
                                        }
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "buy_area__receiverMemberId__form__checkBtn",
                                          class: {
                                            disabled:
                                              _vm.isReceiverMemberIdCheak
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.checkRevicerMemberId()
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                確認\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "buy_area__receiverMemberId__errorMsg"
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.memberIdErrorMsg) +
                                          "\n            "
                                      )
                                    ]
                                  ),
                                  _vm.isReceiverMemberIdCheak
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "buy_area__receiverMemberId__successMsg"
                                        },
                                        [
                                          _vm._v(
                                            "\n              將贈送禮物給ID: " +
                                              _vm._s(_vm.receiverMemberId) +
                                              "\n            "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            : _vm._e()
                        ]),
                        _c("div", { staticClass: "productionArea" }, [
                          _vm._m(2),
                          _c("div", { staticClass: "productionArea__price" }, [
                            _c("div", { staticClass: "price" }, [
                              _c("span", [_vm._v("NT.")]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.nowProductObj.Amount * _vm.butQuantity
                                  )
                                )
                              ]),
                              _c("span", [_vm._v(" / 組")])
                            ]),
                            _vm._m(3)
                          ]),
                          _vm.needShowQuantityControl
                            ? _c("div", { staticClass: "buy_area__quantity" }, [
                                _c("div", {
                                  staticClass:
                                    "buy_area__quantity__arrow decrease",
                                  on: {
                                    click: function($event) {
                                      return _vm.quantityAdd("dec")
                                    }
                                  }
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "buy_area__quantity__content"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "buy_area__quantity__content__number"
                                      },
                                      [
                                        _c("input", {
                                          attrs: {
                                            type: "number",
                                            min: 1,
                                            max: 999
                                          },
                                          domProps: { value: _vm.butQuantity },
                                          on: {
                                            input: _vm.quantityHandler,
                                            blur: function($event) {
                                              _vm.butQuantity < 1
                                                ? (_vm.butQuantity = 1)
                                                : _vm.butQuantity
                                            }
                                          }
                                        })
                                      ]
                                    )
                                  ]
                                ),
                                _c("div", {
                                  staticClass:
                                    "buy_area__quantity__arrow increase",
                                  on: {
                                    click: function($event) {
                                      return _vm.quantityAdd("add")
                                    }
                                  }
                                }),
                                _c(
                                  "div",
                                  { staticClass: "buy_area__quantity__unit" },
                                  [_vm._v("組")]
                                )
                              ])
                            : _vm._e()
                        ])
                      ]
                    )
                  : _vm._e()
              ]),
              _c(
                "div",
                {
                  staticClass: "unlockSingle__toBuy__buyArea",
                  class: { isAll: _vm.nowProductObj.Code == "All" }
                },
                [
                  _vm.useType == "personal"
                    ? _c(
                        "div",
                        { staticClass: "unlockSingle__toBuy__buyArea__price" },
                        [
                          _c("span", [_vm._v("NT$")]),
                          _c("span", [_vm._v(_vm._s(_vm.nowProductObj.Amount))])
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "unlockSingle__toBuy__buyArea__btn",
                      class: { disabled: _vm.alreadyBuyPersonal },
                      on: {
                        click: function($event) {
                          return _vm.toBuy(_vm.nowProductObj)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.alreadyBuyPersonal ? "已購買" : "立即購買"
                          ) +
                          "\n        "
                      ),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/運勢書共用/立即購買.svg"),
                          alt: ""
                        }
                      })
                    ]
                  )
                ]
              )
            ]
          ),
          _vm.nowProductObj.Code != "All"
            ? _c("div", { staticClass: "unlockSingle__toBuyAll" }, [
                _c("div", { staticClass: "unlockSingle__toBuyAll__text" }, [
                  _vm._v("\n      購買全部星座只需 400 元喔～\n    ")
                ]),
                _c(
                  "div",
                  {
                    staticClass: "unlockSingle__toBuyAll__btn",
                    on: {
                      click: function($event) {
                        return _vm.toBuyAll()
                      }
                    }
                  },
                  [
                    _vm._v("\n      全部購買\n      "),
                    _c("img", {
                      attrs: {
                        src: require("@/assets/運勢書共用/立即購買.svg"),
                        alt: ""
                      }
                    })
                  ]
                )
              ])
            : _vm._e(),
          _c("BuyNotice", { attrs: { notice: _vm.nowProductObj.Notice } }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showAlreadyBuyToast,
                  expression: "showAlreadyBuyToast"
                }
              ],
              staticClass: "alreadyBuyToast"
            },
            [
              _vm._v("\n    本篇內容已解鎖\n    "),
              _c("span", { staticClass: "hidden-mobile-down" }, [_vm._v("，")]),
              _c("br", { staticClass: "hidden-mobile-up" }),
              _vm._v("\n    不需要再進行購買囉~\n  ")
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "unlockSingle__intro__title" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/運勢書共用/解答之書介紹icon.svg"),
          alt: ""
        }
      }),
      _vm._v("\n      解答之書介紹\n    ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "buy_area__sectionTitle mt_18 mb_10" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/占星小鋪/gift-icon-white.png"),
          alt: ""
        }
      }),
      _vm._v("\n            送禮方式\n          ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "buy_area__sectionTitle mt_18 mb_2" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/占星小鋪/gift-icon-white.png"),
          alt: ""
        }
      }),
      _vm._v("\n            禮物內容\n          ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "payMethods" }, [
      _c("img", {
        attrs: { src: require("../../assets/占星小鋪/cc-visa.svg"), alt: "" }
      }),
      _c("img", {
        attrs: { src: require("../../assets/占星小鋪/master.svg"), alt: "" }
      }),
      _c("img", {
        attrs: { src: require("../../assets/占星小鋪/Vector.svg"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }