<template>
  <div class="unlockSingle" v-if="nowProductObj">
    <div class="unlockSingle__bg"></div>
    <div class="unlockSingle__title">
      <div class="unlockSingle__title__inside">
        <h1>
          {{
            nowProductObj.Code == "All" ? "全部購買" : nowProductObj.cn + "座"
          }}
        </h1>
        <h2>
          {{
            nowProductObj.Code == "All"
              ? nowProductObj.Name.split("-")[0]
              : nowProductObj.en
          }}
        </h2>
      </div>
    </div>
    <div class="unlockSingle__quote" v-html="nowProductObj.Description"></div>
    <div class="unlockSingle__intro">
      <div class="unlockSingle__intro__title">
        <img src="@/assets/運勢書共用/解答之書介紹icon.svg" alt="" />
        解答之書介紹
      </div>
      <div class="unlockSingle__intro__content">
        <div
          class="unlockSingle__intro__content__word"
          v-html="nowProductObj.Introduction"
        ></div>
      </div>
    </div>
    <div
      class="unlockSingle__toBuy"
      :class="{ isPersonal: useType == 'personal' }"
    >
      <!-- 選擇自用或送禮 -->
      <div class="unlockSingle__toBuy__buySetting">
        <div class="unlockSingle__toBuy__buySetting__useType">
          <div class="buy_area__useType__switch">
            <label for="personal" :class="{ active: useType == 'personal' }">
              <input
                type="radio"
                name="useType"
                id="personal"
                value="personal"
                v-model="useType"
              />
              <span>自用</span>
            </label>
            <label for="gift" :class="{ active: useType == 'gift' }">
              <input
                type="radio"
                name="useType"
                id="gift"
                value="gift"
                v-model="useType"
              />
              <span>送禮</span>
            </label>
          </div>
        </div>

        <div
          class="unlockSingle__toBuy__buySetting__container"
          v-if="useType == 'gift'"
        >
          <!-- 送禮方式設定區塊 -->
          <div class="giftArea">
            <div class="buy_area__sectionTitle mt_18 mb_10">
              <img src="@/assets/占星小鋪/gift-icon-white.png" alt="" />
              送禮方式
            </div>
            <!-- 選擇送禮模式 -->
            <div class="buy_area__giftMode">
              <div class="buy_area__giftMode__radioGruop">
                <label for="memberId">
                  <input
                    type="radio"
                    name="giftMode"
                    id="memberId"
                    value="memberId"
                    v-model="giftMode"
                  />
                  <span>會員 ID</span>
                </label>
                <label for="code">
                  <input
                    type="radio"
                    name="giftMode"
                    id="code"
                    value="code"
                    v-model="giftMode"
                  />
                  <span>兌換碼</span>
                </label>
              </div>
              <div class="buy_area__giftMode__notice">
                <span v-show="giftMode == 'memberId'"
                  >贈送禮物到對方的禮物盒。</span
                >
                <span v-show="giftMode == 'code'"
                  >以兌換碼方式贈送禮物給收禮者。</span
                >
              </div>
            </div>
            <!-- 輸入送禮對象 ID -->
            <div
              class="buy_area__receiverMemberId"
              :class="{ onError: memberIdErrorMsg }"
              v-if="useType == 'gift' && giftMode == 'memberId'"
            >
              <div class="buy_area__receiverMemberId__form">
                <input
                  type="text"
                  id="receiverMemberId"
                  v-model.trim="receiverMemberId"
                  @input="memberIdErrorMsg = ''"
                  :disabled="isReceiverMemberIdCheak"
                  placeholder="請輸入對方會員 ID"
                />
                <div
                  class="buy_area__receiverMemberId__form__checkBtn"
                  :class="{ disabled: isReceiverMemberIdCheak }"
                  @click="checkRevicerMemberId()"
                >
                  確認
                </div>
              </div>

              <div class="buy_area__receiverMemberId__errorMsg">
                {{ memberIdErrorMsg }}
              </div>
              <div
                class="buy_area__receiverMemberId__successMsg"
                v-if="isReceiverMemberIdCheak"
              >
                將贈送禮物給ID: {{ receiverMemberId }}
              </div>
            </div>
          </div>

          <!-- 商品內容顯示區塊 -->
          <div class="productionArea">
            <!-- 內容 -->
            <div class="buy_area__sectionTitle mt_18 mb_2">
              <img src="@/assets/占星小鋪/gift-icon-white.png" alt="" />
              禮物內容
            </div>
            <div class="productionArea__price">
              <div class="price">
                <span>NT.</span>
                <span>{{ nowProductObj.Amount * butQuantity }}</span>
                <span> / 組</span>
              </div>
              <div class="payMethods">
                <img src="../../assets/占星小鋪/cc-visa.svg" alt="" />
                <img src="../../assets/占星小鋪/master.svg" alt="" />
                <img src="../../assets/占星小鋪/Vector.svg" alt="" />
              </div>
            </div>

            <!-- 選擇數量 -->
            <div class="buy_area__quantity" v-if="needShowQuantityControl">
              <div
                class="buy_area__quantity__arrow decrease"
                @click="quantityAdd('dec')"
              ></div>
              <div class="buy_area__quantity__content">
                <div class="buy_area__quantity__content__number">
                  <input
                    type="number"
                    :min="1"
                    :max="999"
                    :value="butQuantity"
                    @input="quantityHandler"
                    @blur="butQuantity < 1 ? (butQuantity = 1) : butQuantity"
                  />
                </div>
              </div>
              <div
                class="buy_area__quantity__arrow increase"
                @click="quantityAdd('add')"
              ></div>
              <div class="buy_area__quantity__unit">組</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 購買按鈕 -->
      <div
        class="unlockSingle__toBuy__buyArea"
        :class="{ isAll: nowProductObj.Code == 'All' }"
      >
        <div
          class="unlockSingle__toBuy__buyArea__price"
          v-if="useType == 'personal'"
        >
          <span>NT$</span>
          <span>{{ nowProductObj.Amount }}</span>
        </div>
        <div
          class="unlockSingle__toBuy__buyArea__btn"
          :class="{ disabled: alreadyBuyPersonal }"
          @click="toBuy(nowProductObj)"
        >
          {{ alreadyBuyPersonal ? "已購買" : "立即購買" }}
          <img src="@/assets/運勢書共用/立即購買.svg" alt="" />
        </div>
      </div>
    </div>

    <div class="unlockSingle__toBuyAll" v-if="nowProductObj.Code != 'All'">
      <div class="unlockSingle__toBuyAll__text">
        購買全部星座只需 400 元喔～
      </div>
      <div class="unlockSingle__toBuyAll__btn" @click="toBuyAll()">
        全部購買
        <img src="@/assets/運勢書共用/立即購買.svg" alt="" />
      </div>
    </div>
    <BuyNotice :notice="nowProductObj.Notice"></BuyNotice>

    <div class="alreadyBuyToast" v-show="showAlreadyBuyToast">
      本篇內容已解鎖
      <span class="hidden-mobile-down">，</span>
      <br class="hidden-mobile-up" />
      不需要再進行購買囉~
    </div>
  </div>
</template>

<script>
import BuyNotice from "@/components/運勢書2024/BuyNotice.vue";
import BuyBook2024Mixin from "@/mixin/buyBook2024Mixin.js";
export default {
  mixins: [BuyBook2024Mixin],
  components: {
    BuyNotice,
  },
  data() {
    return {
      showAlreadyBuyToast: false,
      butQuantity: 1,
      useType: "personal", //gift
      giftMode: "memberId", //code
      receiverMemberId: "",
      isReceiverMemberIdCheak: false,
      memberIdErrorMsg: "",
    };
  },
  computed: {
    _buyAllObj() {
      return this.$store.getters.get_book2024_list.find(
        (item) => item.Code === "All"
      );
    },
    astroId() {
      return this.$route.params.id;
    },
    nowProductObj() {
      if (this.astroId == this._buyAllObj.ProductID) return this._buyAllObj;
      return this.$store.getters.get_book2024_list.find(
        (item) => item.en === this.astroId
      );
    },
    alreadyBuyPersonal() {
      return this.$route.query.disabled && this.useType == "personal";
    },
    needShowQuantityControl() {
      return this.useType === "gift" && this.giftMode === "code";
    },
  },
  created() {
    // if (this.nowProductObj.isUnlock) this.$router.push({ name: "Book2024My" });
    this.useType = this.$route.query.useType || "personal";
  },
  methods: {
    async toBuy(nowProductObj) {
      if (this.useType === "gift" && this.giftMode === "memberId") {
        if (!this.isReceiverMemberIdCheak && !this.memberIdErrorMsg) {
          this.memberIdErrorMsg = "請點擊確認按鈕來驗證該會員 ID 是否正確";
          this.scrollToError();
          return;
        }
        if (this.memberIdErrorMsg) {
          this.scrollToError();
          return;
        }
      } else {
        if (nowProductObj.isUnlock) {
          this.showAlreadyBuyToast = true;
          setTimeout(() => {
            this.showAlreadyBuyToast = false;
          }, 2000);
          return;
        }
      }

      this.mixin_buySingle(
        nowProductObj,
        this.useType,
        this.giftMode,
        this.receiverMemberId,
        this.butQuantity
      );
    },
    toBuyAll() {
      // this.mixin_buyAll(
      //   this.giftMode,
      //   this.useType,
      //   this.receiverMemberId,
      //   this.butQuantity
      // );
      this.$router.push({
        name: "Book2024UnlockSingle",
        params: { id: this._buyAllObj.ProductID },
        query: {
          disabled: this._buyAllObj.isUnlock,
          useType: this.useType,
        },
      });
    },
    async checkRevicerMemberId() {
      if (this.isReceiverMemberIdCheak) return;
      try {
        let res = await this.$API.GET_CheckBuyGift(
          this.receiverMemberId * 1,
          this.nowProductObj.ProductID
        );
        let resObj = res.data.Data;
        console.log(resObj);
        //檢核通過
        if (resObj.Result) {
          this.isReceiverMemberIdCheak = true;
          this.memberIdErrorMsg = "";
        } else {
          this.memberIdErrorMsg = resObj.FailMessage;
          if (this.memberIdErrorMsg.includes("已")) {
            this.$imageNotice({
              imageIdx: 1,
              title: "朋友已經買過囉",
              desc: `您想贈送的對象：(ID:${this.receiverMemberId}) 已經購買過，可以再從星星雜貨店另外挑選禮物喔！`,
              okText: "前往星星雜貨店",
              okEvent: () => {
                this.$router.push("/store");
              },
              cancelText: "返回當前頁面",
            });
          }
        }
      } catch (e) {
        console.error("[Check Revicer MemberId]", e);
        this.memberIdErrorMsg = "網路連線失敗，請稍後再試";
      }
    },
    quantityHandler(event) {
      let value = event.target.value;
      if (value < 0) {
        this.butQuantity = 0;
      } else if (value > 999) {
        this.butQuantity = 999;
      } else {
        this.butQuantity = value;
      }
      this.$forceUpdate();
    },
    quantityAdd(type) {
      if (type === "add") {
        if (this.butQuantity < 999) {
          this.butQuantity++;
        }
      } else {
        if (this.butQuantity > 1) {
          this.butQuantity--;
        }
      }
    },
    scrollToError() {
      setTimeout(() => {
        let errorEl = document.querySelector(".onError");
        if (errorEl) {
          errorEl.scrollIntoView();
          window.scrollBy(0, -150);
        }
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";

.unlockSingle {
  margin: -98px auto -80px;
  padding: 260px 0 80px;
  background: url("../../assets/運勢書共用/商品介紹_Web.png");
  min-height: 70vw;
  background-size: 100% !important;
  background-repeat: no-repeat !important;
  background-position: top center !important;
  @include mobile {
    padding: 144px 0 55px;
    margin: -60px auto -55px;
    min-height: 193.3vw;
    background: url("../../assets/運勢書共用/商品介紹_Mobile.png");
  }
  &__bg {
    width: 390px;
    height: 300px;
    background: url("../../assets/運勢書共用/單一商品介紹背景.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 53px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 45;
    @include mobile {
      width: 279.956px;
      height: 200px;
      top: 0;
      background: none;
    }
  }
  &__title {
    width: auto;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    &__inside {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      h1 {
        color: #fff;
        font-size: 44px;
        font-weight: 700;
        line-height: 132%;
        letter-spacing: 3.52px;
        @include mobile {
          font-size: 32px;
          font-weight: 700;
          line-height: 132%;
          letter-spacing: 2.56px;
        }
      }
      h2 {
        color: #fff;
        font-family: El Messiri;
        font-size: 16.651px;
        font-weight: 400;
        line-height: 132%;
        letter-spacing: 0.833px;
        @include mobile {
          font-size: 12px;
          font-weight: 400;
          line-height: 132%;
          letter-spacing: 0.6px;
        }
      }
    }
  }
  &__quote {
    font-weight: 400;
    max-width: 457px;
    margin: 40px auto 68px;
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    line-height: 200%;
    letter-spacing: 2px;
    text-align: center;
    @include mobile {
      margin: 40px 52px 0;
      font-size: 16px;
      line-height: 160%;
      letter-spacing: 0.8px;
      text-align: left;
    }
  }
  &__intro {
    max-width: 672px;
    margin: 0 auto;
    margin-top: 40px;
    @include mobile {
      margin: 69px 52px 0;
    }
    &__title {
      color: #fff;
      font-weight: 400;
      font-size: 24px;
      line-height: 160%;
      letter-spacing: 1.2px;
      display: flex;
      align-items: center;
      @include mobile {
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.8px;
      }
      img {
        width: 25px;
        height: 30px;
        margin-right: 12px;
        @include mobile {
          width: 25px;
          height: 30px;
          margin-right: 8px;
        }
      }
    }
    &__content {
      margin-top: 24px;
      @include mobile {
        margin: 20px 0 0;
      }
      &__word {
        color: #fff;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 200%;
        letter-spacing: 1.8px;
        text-align: justify;
        @include mobile {
          font-size: 14px;
          font-weight: 400;
          line-height: 200%; /* 28px */
          letter-spacing: 0.7px;
        }
      }
    }
  }
  &__toBuy {
    max-width: 672px;
    margin: 64px auto 32px;
    @include mobile {
      margin: 48px 52px;
      flex-direction: column;
      align-items: flex-start;
    }
    //自用模式
    &.isPersonal {
      display: flex;
      justify-content: space-between;
      @include mobile {
        flex-direction: column;
      }
      & > .unlockSingle__toBuy__buySetting {
        width: 50%;
        @include mobile {
          width: 100%;
        }
      }
      & > .unlockSingle__toBuy__buyArea {
        margin-top: 0;
        width: 50%;
        display: flex;
        justify-content: space-between;
        @include mobile {
          margin-top: 36px;
          width: 100%;
        }
      }
    }

    //購買按鈕
    &__buyArea {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 24px;
      @include mobile {
        margin-top: 36px;
        width: 100%;
        justify-content: space-between;
      }
      &.isAll {
        margin-bottom: 32px;
        @include mobile {
          margin-bottom: 24px;
        }
      }
      &__price {
        color: #fff;
        font-size: 32px;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 1.2px;
        font-family: Abhaya Libre;
        @include mobile {
          font-size: 24px;
          font-weight: 700;
          line-height: 150%;
          letter-spacing: 1px;
        }
      }
      &__btn {
        display: flex;
        align-items: center;
        color: #fff;
        border-radius: 50px;
        border: 1px solid #fff;
        background: #214c73;
        box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.32);
        padding: 8px 24px 10px 24px;

        font-size: 20px;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 1px;
        cursor: pointer;
        @include mobile {
          padding: 6px 16px 4px 16px;
          font-size: 16px;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: 0.8px;
        }
        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }
        img {
          width: 24px;
          height: 24px;
          margin-left: 8px;
          @include mobile {
            width: 23px;
            margin-left: 4px;
          }
        }
      }
    }
  }

  &__toBuyAll {
    max-width: 672px;
    margin: 0 auto 48px;
    padding: 20px 28px;
    border-radius: 15px;
    background: rgba(#fff, 0.1);
    display: flex;
    justify-content: space-between;
    @include mobile {
      margin: 0 52px 48px;
      padding: 16px 20px;
      justify-content: space-between;
      flex-direction: column;
      align-items: flex-end;
    }
    &__text {
      color: #fff;
      font-size: 18px;
      font-weight: 400;
      line-height: 200%;
      letter-spacing: 1.8px;
      @include mobile {
        font-size: 16px;
        letter-spacing: 0.8px;
        margin-right: auto;
      }
    }
    &__btn {
      color: #fff;
      font-size: 20px;
      font-weight: 400;
      line-height: 150%; /* 30px */
      letter-spacing: 1px;
      align-items: center;
      position: relative;
      z-index: 0;
      display: inline-flex;
      cursor: pointer;
      @include mobile {
        margin-top: 12px;
        font-size: 16px;
        letter-spacing: 0.8px;
      }
      img {
        width: 24px;
        height: 24px;
        margin-left: 8px;
        @include mobile {
          width: 29px;
          margin-left: 8px;
        }
      }
      &:before {
        content: "";
        width: 100%;
        height: 16px;
        border-radius: 4.765px;
        background-color: #d3409d;
        position: absolute;
        left: -0.5em;
        bottom: 0;
        z-index: -1;
        @include mobile {
          height: 16px;
          bottom: -0.1em;
        }
      }
    }
  }
}

//已購買提醒
.alreadyBuyToast {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 390px;
  height: 112px;
  border-radius: 20px;
  border: 0.8px solid #d9d9d9;
  background: rgba(33, 76, 115, 0.6);
  backdrop-filter: blur(7.5px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;

  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 1.08px;

  @include mobile {
    width: 256px;
    height: 96px;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 1.28px;
  }
}

//購買設定
.unlockSingle__toBuy__buySetting {
  width: 100%;
  //選擇自用或送禮
  &__useType {
    display: flex;
    flex-direction: column;
    .buy_area__useType__switch {
      display: flex;
      width: 184px;
      padding: 4px;
      background-color: rgba(252, 252, 252, 0.1);
      border-radius: 4px;
      label {
        flex-grow: 1;
        height: 32px;
        border-radius: 4px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        cursor: pointer;
        input {
          display: none;
        }
        &.active {
          background: #fff;
          color: #214c73;
        }
      }
    }
  }
  //giftArea和productionArea的容器
  &__container {
    display: flex;
    justify-content: space-between;
    @include mobile {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .buy_area__sectionTitle {
    font-size: 16px;
    line-height: 28px;
    display: flex;
    align-content: center;
    color: #fff;
    img {
      width: 28px;
      height: 28px;
      margin-right: 0;
    }
  }
}

.giftArea {
  width: 271px;
  //選擇送禮模式
  .buy_area__giftMode {
    &__radioGruop {
      display: flex;
      label {
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 28px;
        color: #fff;
        cursor: pointer;
        margin-right: 24px;
        input {
          display: none;
        }
        span {
          position: relative;
          padding-left: 30px;
          &:before {
            content: "";
            width: 22px;
            height: 22px;
            border-radius: 50%;
            background: #fff;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
          &:after {
            content: "";
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #214c73;
            position: absolute;
            left: 6px;
            top: 50%;
            transform: translateY(-50%);
            display: none;
          }
        }
        input:checked + span {
          &:after {
            display: block;
          }
        }
      }
    }
    &__notice {
      font-size: 14px;
      line-height: 24px;
      color: #fff;
      margin-top: 8px;
    }
  }
  // 送禮對象 ID
  .buy_area__receiverMemberId {
    &.onError {
      .buy_area__receiverMemberId__form input {
        border-color: #e2562c;
      }
    }
    &__form {
      height: 28px;
      margin-top: 20px;
      display: flex;
      align-items: center;
      gap: 6px;
      input {
        display: inline-block;
        flex-grow: 1;
        min-width: 140px;
        border-radius: 100px;
        border: 1px solid #ffffff;
        background-color: transparent;
        line-height: 22px;
        font-size: 14px;
        letter-spacing: 1.08px;
        color: #fff;
        padding: 3px 16px;
        &::placeholder {
          color: #d9d9d9;
        }
        &:focus {
          outline: none;
        }
        @include mobile {
          font-size: 14px;
        }
      }
      &__checkBtn {
        width: 64px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 50px;
        border: 1px solid #fff;
        background: #214c73;
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 1px;
        cursor: pointer;
        @include mobile {
          font-weight: 400;
          line-height: 150%;
          letter-spacing: 0.8px;
        }
        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }
    &__errorMsg {
      max-width: 166px;
      font-size: 14px;
      line-height: 20px;
      color: #d3409d;
      background-color: #fff;
      margin-top: 12px;
    }
    &__successMsg {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #fff;
      margin-top: 12px;
    }
  }
}

.productionArea {
  width: 271px;
  // 內容
  &__price {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    margin-bottom: 40px;
    margin-top: 24px;
    .price {
      display: flex;
      align-items: flex-end;
      color: #fff;

      & > span:nth-of-type(1) {
        font-size: 16px;
      }
      & > span:nth-of-type(2) {
        font-size: 40px;
        font-weight: 700;
        line-height: 1;
      }
    }
    .payMethods {
      display: flex;
      > img {
        height: 16px;
        margin-left: 6px;
      }
    }
  }
  //選擇數量
  .buy_area__quantity {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    &__arrow {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      font-size: 30px;
      font-weight: 500;
      background: transparent;
      border: 1px solid #fff;
      @include center;
      cursor: pointer;
      position: relative;
      user-select: none;
      @include mobile {
        width: 24px;
        height: 24px;
      }
      &.decrease:after,
      &.increase:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 2px;
        width: 12px;
        border-radius: 3px;
        background-color: #fff;
      }
      &.increase:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 12px;
        width: 2px;
        border-radius: 3px;
        background-color: #fff;
      }
    }
    .buy_area__quantity__content {
      display: flex;
      align-items: center;
      &__number {
        background: #fff;
        border-radius: 20px;
        color: $theme-color-2;
        text-align: center;
        input {
          border: none;
          background: none;
          color: #17445c;
          text-align: center;
          width: 49px;
          height: 28px;
          font-size: 14px;
          line-height: 20px;
          font-weight: 700;
          padding: 4px 12px;
          @include mobile {
            width: 49px;
            height: 28px;
            font-size: 14px;
            line-height: 20px;
            padding: 4px 12px;
          }
          &:focus {
            outline: none;
          }
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }
      }
    }
    .buy_area__quantity__unit {
      font-size: 14px;
      line-height: 20px;
      font-weight: 700;
      color: #fff;
      margin-left: 16px;
      @include mobile {
        font-size: 14px;
      }
    }
  }
}
</style>
